import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";
import AdminsNewAndEditModal from "./AdminsNewAndEditModal";

import { DebounceInput } from "react-debounce-input";
import { get } from "helpers/api_helper";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // const [merchantOptions, setMerchantOptions] = useState([]);

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    fetchAdmins(0, limit);
    // fetchMerchants();
  }, [searchKey]);

  const fetchAdmins = async (page, limit) => {
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("page", page);
    params.append("limit", limit);

    axios
      .get(`${API_URL}admin/list`, {
        searchKey,
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setAdmins(response?.data.data._admins);
        setTotalRows(response?.data.data.totalCount);
      })
      .catch((err) => {
        history.push("./login");

        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchAdmins(page - 1, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchAdmins(page - 1, newPerPage);
  };

  const columns = [
    {
      name: "First Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.firstName}</h5>
      ),
      sortable: true,
    },

    {
      name: "Last Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.lastName}</h5>
      ),
      sortable: true,
    },

    {
      name: "Phone",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.phone}</h5>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.email}</h5>
      ),
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.role}</h5>,
      sortable: true,
    },
    {
      name: "isDeleted",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row.isDeleted ? "True" : "False"}
        </h5>
      ),
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <ul className="list-inline font-size-20 contact-links mb-0 text-center cursor-pointer">
          <Link to="#">
            <i
              className="mdi mdi-delete font-size-18 "
              style={{
                color: "#1525ac",
              }}
              id="deletetooltip"
              onClick={() => onClickAdminDelete(row)}
            ></i>
          </Link>
          <li className="list-inline-item px-2">
            <Link to="#">
              <i
                style={{
                  color: "#333333",
                }}
                className="mdi mdi-pencil font-size-18 "
                id="edittooltip"
                onClick={() => handleEditAdmin(row)}
              ></i>
            </Link>
          </li>
        </ul>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: " Details",
      cell: (row) => (
        <Link className="btn btn-secondary" to={`/admin/details/${row._id}`}>
          Details
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditAdmin = (item) => {
    setModal(true);
    setModalValue("edit");
    setSelectedAdmin(item);
  };

  const onClickAdminDelete = (item) => {
    setSelectedAdmin(item);
    setDeleteModal(true);
  };

  const handleDeleteAdmin = (item) => {
    const body = {
      id: item?._id,
    };
    axios
      .delete(`${API_URL}admin/delete`, {
        data: body,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then(() => {
        fetchAdmins(0, limit);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAdmin}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={selectedAdmin}
      />

      <AdminsNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        selectedAdmin={modalValue === "new" ? {} : selectedAdmin}
        fetchAdmins={fetchAdmins}
        // merchantOptions={merchantOptions}
      />

      <div className="page-content">
        <MetaTags>
          <title>Admins | Mena Checkout</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            // title={props.t("Categories")}
            // breadcrumbItem={props.t("Categories")}
            title=""
            breadcrumbItem="Admins"
          />

          <Row>
            <Col>
              <div className="text-sm-end mb-2">
                <button className="btn font-16 create-btn" onClick={addNew}>
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Admin
                </button>
              </div>
            </Col>
          </Row>

          {/* <Col sm="4">
            <div className="search-box ms-2 mb-2 d-inline-block">
              <div className="position-relative">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `30px`,
                    border: `1px solid gray`,
                    width: `400px`,
                    padding: `10px`,
                  }}
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search English Name.."
                />
              </div>
            </div>
          </Col> */}

          {admins?.length !== 0 ? (
            <>
              <Grid
                data={admins}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Admins</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Admins.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Admins);

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";

import { DebounceInput } from "react-debounce-input";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const history = useHistory();
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    fetchAccounts(0, limit);
    // fetchMerchants();
  }, []);

  const fetchAccounts = async (page, limit) => {
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("limit", limit);

    axios
      .get(`${API_URL}account/admin/list`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setAccounts(response?.data.data._accounts);
        setTotalRows(response?.data.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        history.push("./login");
      });
  };

  const handlePageChange = (page) => {
    fetchAccounts(page - 1, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchAccounts(page - 1, newPerPage);
  };

  const columns = [
    {
      name: "Domain Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.domainName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Balance",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.balance}</h5>
      ),
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.currency}</h5>
      ),
      sortable: true,
    },
    {
      name: "isApproved",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.isApproved ? "True" : "False"}
        </h5>
      ),
      sortable: true,
    },

    {
      name: "Created At",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {moment(row.createdAt).format("DD-MM-YYYY")}
        </h5>
      ),
      sortable: true,
    },
    {
      name: " Details",
      cell: (row) => (
        <Link className="btn btn-secondary" to={`/account/details/${row._id}`}>
          Details
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Accounts | Mena Checkout</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Accounts" />

          {accounts?.length !== 0 ? (
            <>
              <Grid
                data={accounts}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Accounts</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Accounts.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Accounts);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const PackagesNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  selectedPackage,
  fetchPackages,
  merchantOptions,
}) => {
  const [requestError, setRequestError] = useState(null);
  const [handleErrorPlace, setHandleErrorPlace] = useState(null);
  const [checked, setChecked] = useState();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (selectedPackage && selectedPackage.name) || "",
      nameAr: (selectedPackage && selectedPackage.nameAr) || "",
      description: (selectedPackage && selectedPackage.description) || "",
      descriptionAr: (selectedPackage && selectedPackage.descriptionAr) || "",
      price: (selectedPackage && selectedPackage.price / 100) || "",
      monthlyLimit: (selectedPackage && selectedPackage.monthlyLimit) || "",
      percentageWarning:
        (selectedPackage && selectedPackage.percentageWarning) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]*[A-Za-z][A-Za-z\s]*$/, "Must Be English Letters")
        .required("Please enter english name"),
      nameAr: Yup.string()
        .matches(
          /^[\u0600-\u06FF\s]*[\u0600-\u06FF][\u0600-\u06FF\s]*$/,
          "Must Be Arabic Letters"
        )
        .required("Please Enter  arabic name"),
      description: Yup.string()
        .required("description is required")
        .matches(
          /^[A-Za-z\s]*[A-Za-z][A-Za-z\s]*$/,
          "Please Enter English Letters"
        )
        .matches(
          /^[A-Za-z\s]*[A-Za-z][A-Za-z\s]*$/,
          "Please Enter English Letters"
        )
        .matches(/^.{30,300}$/, "description must be more than 30 charachters"),
      descriptionAr: Yup.string()
        .required("description is required")
        .matches(
          /^[\u0600-\u06FF\s]*[\u0600-\u06FF][\u0600-\u06FF\s]*$/,
          "Must Be Arabic Letters"
        )
        .matches(
          /^.{30,300}$/,
          "description Arabic must be more than 30 charachters"
        ),
      price: Yup.string()
        .required("Please enter price")
        .matches(/^[0-9]+$/, "price must be a number"),
      monthlyLimit: Yup.string()
        .required("Please enter Monthly Limit")
        .matches(/^[0-9]+$/, "monthly limit must be a number"),
      percentageWarning: Yup.string()
        .required("Please enter Percentage Warning")
        .matches(/^[0-9]+$/, "monthly limit must be a number"),
    }),
    onSubmit: (values) => {
      console.log(checked);
      if (modalValue === "edit") {
        const body = {
          name: values.name,
          nameAr: values.nameAr,
          description: values.description,
          descriptionAr: values.descriptionAr,
          price: values.price * 100,
          id: selectedPackage?._id,
          monthlyLimit: values.monthlyLimit,
          percentageWarning: values.percentageWarning,
          unlimited:
            checked !== undefined ? checked : selectedPackage?.unlimited,
        };

        const params = new URLSearchParams();
        // params.append("userDevice", localStorage.getItem("userDevice"));

        axios
          .put(`${API_URL}package/update`, body, {
            headers: {
              userDevice: localStorage.getItem("userDevice"),
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json; charset=utf-8",
            },
          })
          .then(() => {
            fetchPackages(0, 10);
            validation.resetForm();
            setShowModal(false);
          })
          .catch((err) => {
            setRequestError(err.response.data.message);
            setHandleErrorPlace("edit");
          });
      } else {
        const body = {
          name: values.name,
          nameAr: values.nameAr,
          description: values.description,
          descriptionAr: values.descriptionAr,
          price: values.price * 100,
          monthlyLimit: values.monthlyLimit,
          percentageWarning: values.percentageWarning,
          unlimited: checked ? true : false,
        };

        axios
          .post(`${API_URL}package/create`, body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json; charset=utf-8",
              userDevice: localStorage.getItem("userDevice"),
            },
          })
          .then(() => {
            fetchPackages(0, 10);
            validation.resetForm();
            setShowModal(false);
          })
          .catch((err) => {
            setRequestError(err.response.data.message);
            setHandleErrorPlace("create");
          });
      }
      setRequestError(null);
      setHandleErrorPlace(null);
      setChecked();
    },
  });

  const handleTagChange = (selectedOption) => {
    const ids = selectedOption.map((item) => {
      return item.value;
    });
    setSelectedTags(ids);
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onCloseClick();
        setRequestError();
        setChecked();
        validation.resetForm();
      }}
      centered={true}
    >
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit Package" : "Add Package"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Arabic Name</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                  name="description"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Description Arabic</Label>
                <Input
                  name="descriptionAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.descriptionAr || ""}
                  invalid={
                    validation.touched.descriptionAr &&
                    validation.errors.descriptionAr
                      ? true
                      : false
                  }
                />
                {validation.touched.descriptionAr &&
                validation.errors.descriptionAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.descriptionAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Price (USD)</Label>
                <Input
                  name="price"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.price || ""}
                  invalid={
                    validation.touched.price && validation.errors.price
                      ? true
                      : false
                  }
                />
                {validation.touched.price && validation.errors.price ? (
                  <FormFeedback type="invalid">
                    {validation.errors.price}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Monthly Limit</Label>
                <Input
                  name="monthlyLimit"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.monthlyLimit || ""}
                  invalid={
                    validation.touched.monthlyLimit &&
                    validation.errors.monthlyLimit
                      ? true
                      : false
                  }
                />
                {validation.touched.monthlyLimit &&
                validation.errors.monthlyLimit ? (
                  <FormFeedback type="invalid">
                    {validation.errors.monthlyLimit}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Percentage Warning</Label>
                <Input
                  name="percentageWarning"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.percentageWarning || ""}
                  invalid={
                    validation.touched.percentageWarning &&
                    validation.errors.percentageWarning
                      ? true
                      : false
                  }
                />
                {validation.touched.percentageWarning &&
                validation.errors.percentageWarning ? (
                  <FormFeedback type="invalid">
                    {validation.errors.percentageWarning}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3 mt-3">
                <Label className="form-label me-3">UnLimited</Label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  defaultChecked={selectedPackage?.unlimited}
                  onClick={(e) => setChecked(e.target.checked)}
                />
              </div>
            </Col>
          </Row>
          {modalValue === "edit" && handleErrorPlace === "edit" && (
            <p className="text-danger mt-2 text-center">{requestError}</p>
          )}
          {modalValue !== "edit" && handleErrorPlace === "create" && (
            <p className="text-danger mt-2 text-center">{requestError}</p>
          )}
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    onCloseClick();
                    setRequestError();
                    setChecked();
                    validation.resetForm();
                  }}
                  className="btn create-border-btn save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn create-btn save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

PackagesNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  selectedPackage: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchPackages: PropTypes.func,
  merchantOptions: PropTypes.any,
};

export default PackagesNewAndEditModal;

import React from "react";
import { Redirect } from "react-router-dom";

// Profile
// import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import changePassword from "../pages/Authentication/changePassword";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
// import Dashboard from "../pages/Dashboard/index";

import Users from "pages/Users";

import Countries from "pages/Countries";

import Admins from "pages/Admins";
import AdminDetails from "pages/AdminDetails";
import Transactions from "pages/Transactions";
import TransactionsDetails from "pages/TransactionsDetails";

import Packages from "pages/Packages";
// import PaymentGateways from "pages/Payment Gateways";
import UserDetails from "pages/UserDetails";
// import AdminBankAccounts from "pages/AdminBankAccounts";
// import AdminBankAccountDetails from "pages/AdminBankAccountDetails";
import Accounts from "pages/Accounts";
import CountryDetails from "pages/CountryDetails";
import PackageDetails from "pages/Package Details";
import Customers from "pages/Customers";
import CustomerDetails from "pages/CustomerDetails";
import PackagesRequests from "pages/PackagesRequests";
import PackageRequestDetails from "pages/PackageRequestDetails";
import Refund from "pages/Refund";
import AdminRefundDetails from "pages/RefundDetails";
import RefundRequests from "pages/RefundRequests";
import RefundRequestDetails from "pages/RefundRequestDetails";
import AccountDetails from "pages/AccountDetails";
import PayOut from "pages/PayOut";
import PayOutDetails from "pages/PayOutDetails";
import Subscription from "pages/Subscription";
import SubspriptionDetails from "pages/Subspription Details";

const authProtectedRoutes = [
  // { path: "/dashboard", component: Dashboard },

  //profile
  // { path: "/profile", component: UserProfile },

  { path: "/users", component: Users },
  { path: "/accounts", component: Accounts },
  { path: "/payout", component: PayOut },
  { path: "/payout/details/:id", component: PayOutDetails },
  { path: "/account/details/:id", component: AccountDetails },
  { path: "/customers", component: Customers },
  { path: "/customer/details/:id", component: CustomerDetails },
  { path: "/packagesrequests", component: PackagesRequests },
  { path: "/packagerequest/details/:id", component: PackageRequestDetails },
  // { path: "/bankaccount/details/:id", component: AdminBankAccountDetails },
  { path: "/user/details/:id", component: UserDetails },
  { path: "/refunds", component: Refund },

  { path: "/refund/details/:id", component: AdminRefundDetails },
  { path: "/refundrequests", component: RefundRequests },
  { path: "/refundrequest/details/:id", component: RefundRequestDetails },
  { path: "/packages", component: Packages },
  { path: "/package/details/:id", component: PackageDetails },
  { path: "/countries", component: Countries },
  { path: "/country/details/:id", component: CountryDetails },
  // { path: "/paymentsgateways", component: PaymentGateways },
  { path: "/transactions", component: Transactions },
  { path: "/transaction/details/:id", component: TransactionsDetails },
  { path: "/subscription", component: Subscription },
  { path: "/subscription/details/:id", component: SubspriptionDetails },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
];
const superAdminProtectedRoutes = [
  { path: "/admins", component: Admins },
  { path: "/admin/details/:id", component: AdminDetails },
  { path: "*", exact: true, component: () => <Redirect to="/login" /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/changePassword", component: changePassword },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
];

export { publicRoutes, authProtectedRoutes, superAdminProtectedRoutes };

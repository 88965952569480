import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import Loader from "components/Common/Loader";
import TransactionsPerUser from "./transactionsPerUser";

const AccountDetials = () => {
  const [accountItem, setAccountItem] = useState();
  const [isApprovedError, setIsApprovedError] = useState(null);
  const [payout, setPayout] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSingleaccountItemRequest();
  }, []);
  useEffect(() => {
    accountItem?.userId?._id && fetchtotalPayOut();
  }, [accountItem?.userId?._id]);
  const fetchSingleaccountItemRequest = async () => {
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}account/admin/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setAccountItem(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchtotalPayOut = () => {
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("userId", accountItem?.userId?._id);
    axios
      .get(`${API_URL}payout/admin/total`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setPayout(response.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const approveRequest = (e) => {
    const body = {
      accountId: id,
    };
    axios
      .put(`${API_URL}admin/account/approve`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        fetchSingleaccountItemRequest();
      })
      .catch((err) => {
        setIsApprovedError(err.response.data.message);
      });
  };
  console.log(payout);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Account Details| Mena Checkout</title>{" "}
        </MetaTags>

        <Container fluid>
          <div className="d-flex justify-content-between flex-wrap row">
            <Card className="col-3">
              <CardBody className="text-center">
                <h4>Balance</h4>
                <h6>{accountItem && accountItem?.balance / 100}</h6>
              </CardBody>
            </Card>
            <Card className="col-3">
              <CardBody className="text-center">
                <h4>Withdraw</h4>
                <h6>{payout ? payout?.totalPayout / 100 : 0}</h6>
              </CardBody>
            </Card>
          </div>
          <Breadcrumb title="Accounts" breadcrumbItem="Account Details" />
          <Card>
            <CardBody>
              <CardTitle>
                <td>{accountItem?.domainName} Details </td>
              </CardTitle>
              <p className="text-muted mb-4">{accountItem?.description}</p>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Domain Name :</th>
                      <td>{accountItem?.domainName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Currency :</th>
                      <td>{accountItem?.currency}</td>
                    </tr>
                    <tr>
                      <th scope="row">isApproved:</th>
                      <td className="font-size-14 mt-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={accountItem?.isApproved}
                          id="flexCheckDefault"
                          defaultChecked={accountItem?.isApproved}
                          onClick={approveRequest}
                          disabled={accountItem?.isApproved ? true : false}
                        />
                        {isApprovedError && (
                          <span className="ms-3 text-danger">
                            {isApprovedError}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">isDeleted :</th>
                      <td>{accountItem?.isDeleted ? "True" : "False"}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle>
                <td>User Details </td>
              </CardTitle>

              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">First Name :</th>
                      <td>{accountItem?.userId.firstName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Last Name :</th>
                      <td>{accountItem?.userId.lastName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Phone :</th>
                      <td>{accountItem?.userId.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email :</th>
                      <td>{accountItem?.userId.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">isApproved:</th>

                      <td>
                        {accountItem?.userId.isApproved ? "True" : "False"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">isBlocked:</th>

                      <td>
                        {accountItem?.userId.isBlocked ? "True" : "False"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">isValidated :</th>
                      <td>
                        {accountItem?.userId.isValidated ? "True" : "False"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <TransactionsPerUser userId={accountItem?.userId?._id} />
        </Container>
      </div>
    </React.Fragment>
  );
};
AccountDetials.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AccountDetials);

import PropTypes from "prop-types";
import React from "react";

import { Card, CardBody, Col, Row } from "reactstrap";
import DataTable from "react-data-table-component";

const Grid = ({
  data,
  columns,
  totalRows,
  handlePageChange,
  handlePerRowsChange,
}) => {
  const customStyles = {
    headCells: {
      style: {
        background: "rgb(138 138 138)",
        color: "white",
        fontWeight: "bold",
        borderBottom: "1px solid #ddd",
        padding: "12px",
      },
    },
    table: {
      style: {
        border: "1px solid #ddd",
        borderRadius: "4px",
      },
    },
    rows: {
      style: {
        background: "#fff",
        color: "#333",
        "&:nth-child(odd)": {
          background: "#f5f5f5",
        },
        "&:hover": {
          background: "#cecece",
        },
      },
    },
    cells: {
      style: {
        paddingRight: "30px",
      },
    },
    pagination: {
      style: {
        borderTop: "none",
        paddingTop: "60px",
      },
    },
  };
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <DataTable
              columns={columns}
              data={data}
              className="-mt-10"
              customStyles={customStyles}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationPosition="left"
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

Grid.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.any,
  handlePageChange: PropTypes.func,
  handlePerRowsChange: PropTypes.func,
  totalRows: PropTypes.number,
};

export default Grid;

import PropTypes, { number } from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";

import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";

import { DebounceInput } from "react-debounce-input";
import { get } from "helpers/api_helper";
import Loader from "components/Common/Loader";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const Payout = ({ detailId }: any) => {
  const [payout, setPayout] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [hasMorePages, setHasMorePages] = useState(false);
  const [nextPaginationPage, setNextPaginationPage] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [firstPage, setFirstPage] = useState(true);
  const history = useHistory();
  const customStyles = {
    headCells: {
      style: {
        background: "rgb(138 138 138)",
        color: "white",

        fontWeight: "bold",
        borderBottom: "1px solid #ddd",
        padding: "12px",
      },
    },
    table: {
      style: {
        border: "1px solid #ddd",
        borderRadius: "4px",
      },
    },
    rows: {
      style: {
        background: "#fff",
        color: "#333",
        "&:nth-child(odd)": {
          background: "#f5f5f5",
        },
        "&:hover": {
          background: "rgb(156 169 175 / 49%)",
        },
      },
    },
    cells: {
      style: {
        paddingRight: "30px",
      },
    },
    pagination: {
      style: {
        borderTop: "none",
        paddingTop: "60px",
      },
    },
  };
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    fetchPayout(limit);
  }, []);

  const fetchPayout = async (limit) => {
    const params = new URLSearchParams();
    params.append("limit", limit);
    setFirstPage(true);

    axios
      .get(`${API_URL}payout/admin/list`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setPayout(response?.data.data.data);
        setHasMorePages(response?.data.data.has_more);
        setNextPaginationPage(
          response?.data.data?.data[response?.data.data?.data.length - 1]?.id
        );
        setTotalRows(response?.data.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        history.push("./login");
      });
  };

  const fetchPayoutPagination = async (page, limit) => {
    setHasMorePages(null);
    const params = new URLSearchParams();
    params.append("startingAfter", nextPaginationPage);
    params.append("limit", limit);

    axios
      .get(`${API_URL}payout/admin/list`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setPayout(response?.data.data.data);
        setHasMorePages(response?.data.data.has_more);
        setNextPaginationPage(
          response?.data.data?.data[response?.data.data?.data.length - 1]?.id
        );
        setTotalRows(response?.data.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        history.push("./login");
      });
  };

  const handleNextPageChange = async () => {
    fetchPayoutPagination(nextPaginationPage, limit);

    setFirstPage(false);
  };

  // const handlePrevPerRowsChange = async () => {
  //   fetchPayoutPagination(limit);
  // };

  const columns = [
    {
      name: "type",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.type}</h5>,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.amount / 100}</h5>
      ),
      sortable: true,
    },

    {
      name: "Currency",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.currency}</h5>
      ),
      sortable: true,
    },
    {
      name: "CreatedAt",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {moment(row?.created * 1000).format("DD-MM-YYYY")}
          {" , "}
          {moment(row?.created).format("h:mm:ss a")}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Arrival Date",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {moment(row?.arrival_date * 1000).format("DD-MM-YYYY")}
          {" , "}
          {moment(row?.arrival_date).format("h:mm:ss a")}
        </h5>
      ),
      sortable: true,
    },

    {
      name: "status",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.status}</h5>
      ),
      sortable: true,
    },

    {
      name: " Details",
      cell: (row) => (
        <Link className="btn btn-secondary" to={`/payout/details/${row.id}`}>
          Details
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditAdmin = (item) => {
    setModal(true);
    setModalValue("edit");
    setSelectedAdmin(item);
  };

  const onClickAdminDelete = (item) => {
    setSelectedAdmin(item);
    setDeleteModal(true);
  };

  return (
    <React.Fragment>
      <div className="page-content transaction-page">
        <MetaTags>
          <title>Payout | Mena Checkout</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Payout" />

          {payout?.length !== 0 ? (
            <>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <DataTable
                        columns={columns}
                        data={payout}
                        className="-mt-10"
                        customStyles={customStyles}
                        paginationServer
                      />
                      <div className="w-100 text-center custom-pagination">
                        <p className="m-0 me-3"> Rows Per Page: {limit}</p>
                        <button
                          disabled={firstPage}
                          className="pagination-prev-btn me-1"
                          onClick={() => fetchPayout(limit)}
                        >
                          <MdOutlineKeyboardDoubleArrowLeft />
                        </button>
                        {/* <button onClick disabled>
                  Previous
                </button> */}
                        <button
                          className="pagination-next-btn"
                          onClick={handleNextPageChange}
                          disabled={!hasMorePages}
                        >
                          <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>payout</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Payout.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Payout);

import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import Loader from "components/Common/Loader";

const PayoutDetails = () => {
  const [payout, setPayout] = useState();
  const [loader, setLoader] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSinglePayoutRequest();
  }, []);

  const fetchSinglePayoutRequest = async () => {
    setLoader(true);
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}payout/admin/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setPayout(response.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      {loader ? (
        <Loader />
      ) : (
        <div className="page-content">
          <MetaTags>
            <title>Payout Details| Mena Checkout</title>{" "}
          </MetaTags>
          <Container fluid>
            <Breadcrumb title="Payout" breadcrumbItem="Payout Details" />
            <Card>
              <CardBody>
                <p className="text-muted mb-4">{payout?.description}</p>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Type :</th>
                        <td>{payout?.type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Source Type :</th>
                        <td>{payout?.source_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Description :</th>
                        <td>{payout?.description}</td>
                      </tr>
                      <tr>
                        <th scope="row">Amount :</th>
                        <td>{payout?.amount / 100}</td>
                      </tr>
                      <tr>
                        <th scope="row">Currency:</th>
                        <td>{payout?.currency}</td>
                      </tr>
                      <tr>
                        <th scope="row">CreatedAt :</th>
                        <td>
                          {moment(payout?.created * 1000).format("DD-MM-YYYY")}
                          {" , "}
                          {moment(payout?.created).format("h:mm:ss a")}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Arrival Date :</th>
                        <td>
                          {moment(payout?.arrival_date * 1000).format(
                            "DD-MM-YYYY"
                          )}
                          {" , "}
                          {moment(payout?.arrival_date).format("h:mm:ss a")}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Status :</th>
                        <td>{payout?.status}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};
PayoutDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PayoutDetails);

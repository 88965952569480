import PropTypes, { number } from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";

import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import Loader from "components/Common/Loader";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

export default function SubscriptionList({ status }: any) {
  const [subscription, setSubscription] = useState([]);

  const limit = 10;

  const [hasMorePages, setHasMorePages] = useState(false);
  const [nextPaginationPage, setNextPaginationPage] = useState(null);

  const [firstPage, setFirstPage] = useState(true);
  const history = useHistory();
  const customStyles = {
    headCells: {
      style: {
        background: "rgb(138 138 138)",
        color: "white",

        fontWeight: "bold",
        borderBottom: "1px solid #ddd",
        padding: "12px",
      },
    },
    table: {
      style: {
        border: "1px solid #ddd",
        borderRadius: "4px",
      },
    },
    rows: {
      style: {
        background: "#fff",
        color: "#333",
        "&:nth-child(odd)": {
          background: "#f5f5f5",
        },
        "&:hover": {
          background: "rgb(156 169 175 / 49%)",
        },
      },
    },
    cells: {
      style: {
        paddingRight: "30px",
      },
    },
    pagination: {
      style: {
        borderTop: "none",
        paddingTop: "60px",
      },
    },
  };
  useEffect(() => {
    fetchsubscription(limit);
  }, []);

  const fetchsubscription = async (limit) => {
    console.log(limit);
    const params = new URLSearchParams();
    params.append("limit", limit);
    params.append("status", status);
    setFirstPage(true);

    axios
      .get(`${API_URL}subscription/admin/list`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setSubscription(response?.data.data.data);
        setHasMorePages(response?.data.data.has_more);
        setNextPaginationPage(
          response?.data.data?.data[response?.data.data?.data.length - 1]?.id
        );
      })
      .catch((err) => {
        console.log(err);
        history.push("./login");
      });
  };

  const fetchsubscriptionPagination = async (page, limit) => {
    setHasMorePages(null);
    const params = new URLSearchParams();
    params.append("startingAfter", nextPaginationPage);
    params.append("limit", limit);
    params.append("status", status);

    axios
      .get(`${API_URL}subscription/admin/list`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setSubscription(response?.data.data.data);
        setHasMorePages(response?.data.data.has_more);
        setNextPaginationPage(
          response?.data.data?.data[response?.data.data?.data.length - 1]?.id
        );
      })
      .catch((err) => {
        console.log(err);
        history.push("./login");
      });
  };
  const handleNextPageChange = async () => {
    fetchsubscriptionPagination(nextPaginationPage, limit);

    setFirstPage(false);
  };

  const columns = [
    {
      name: "Price",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.items?.data[0]?.price?.unit_amount / 100}{" "}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.currency.toUpperCase()}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Created",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {moment(row?.created * 1000).format("DD-MM-YYYY")}
          {" , "}
          {moment(row?.created).format("h:mm:ss a")}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Current Period Start",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {moment(row?.current_period_start * 1000).format("DD-MM-YYYY")}
          {" , "}
          {moment(row?.current_period_start).format("h:mm:ss a")}
        </h5>
      ),
      sortable: true,
    },

    {
      name: "Current Period End",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {moment(row?.current_period_end * 1000).format("DD-MM-YYYY")}
          {" , "}
          {moment(row?.current_period_end).format("h:mm:ss a")}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Count",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.items?.data[0]?.price?.recurring?.interval_count}{" "}
          {row?.items?.data[0]?.price?.recurring?.interval}
        </h5>
      ),
      sortable: true,
    },

    {
      name: " Details",
      cell: (row) => (
        <Link
          className="btn btn-secondary"
          to={`/subscription/details/${row?.id}`}
        >
          Details
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content transaction-page">
        <Container fluid>
          <h4 className="">
            {status.charAt(0).toUpperCase()}
            {status.slice(1).toLowerCase()} Subscriptions
          </h4>
          {subscription?.length !== 0 ? (
            <>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <DataTable
                        columns={columns}
                        data={subscription}
                        className="-mt-10"
                        customStyles={customStyles}
                        paginationServer
                      />
                      <div className="w-100 text-center custom-pagination">
                        <p className="m-0 me-3"> Rows Per Page: {limit}</p>
                        <button
                          disabled={firstPage}
                          className="pagination-prev-btn me-1"
                          onClick={() => fetchsubscription(limit)}
                        >
                          <MdOutlineKeyboardDoubleArrowLeft />
                        </button>
                        {/* <button onClick disabled>
                  Previous
                </button> */}
                        <button
                          className="pagination-next-btn"
                          onClick={handleNextPageChange}
                          disabled={!hasMorePages}
                        >
                          <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Subscriptions</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

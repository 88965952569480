import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
// import UserBankAccounts from "./UserBankAccounts";
import UserTransactions from "./UserTransactions";

const UserDetails = () => {
  const [user, setUser] = useState();
  const [userAccount, setUserAccount] = useState([]);
  const [isApprovedError, setIsApprovedError] = useState(null);
  const [isApprovedAccountError, setIsApprovedAccountError] = useState(null);
  const [isBlockedError, setIsBlockedError] = useState(null);
  const [isValidatedError, setIsValidatedError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const params = useParams();
  const fileInputRef = useRef();

  const userId = params.id;

  useEffect(() => {
    fetchSingleuserRequest();
    fetchUserAccount();
  }, []);

  const fetchSingleuserRequest = () => {
    setIsApprovedError(null);
    setIsBlockedError(null);
    setIsValidatedError(null);
    setIsApprovedAccountError(null);
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", userId);

    axios
      .get(`${API_URL}user/admin/profile`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const approveRequest = (e) => {
    const body = {
      userId,
    };
    axios
      .put(`${API_URL}admin/user/approve`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        fetchSingleuserRequest();
      })
      .catch((err) => {
        setIsApprovedError(err.response.data.message);
      });
  };

  const blockRequest = (e) => {
    const body = {
      userId,
      block: e.target.checked,
    };

    axios
      .put(`${API_URL}admin/user/block`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        fetchSingleuserRequest();
      })
      .catch((err) => {
        setIsBlockedError(err.response.data.message);
      });
  };
  const validateRequest = (e) => {
    const body = {
      userId,
    };

    axios
      .put(`${API_URL}admin/user/validate`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        fetchSingleuserRequest();
      })
      .catch((err) => {
        setIsValidatedError(err.response.data.message);
      });
  };

  const fetchUserAccount = async (page, limit) => {
    setIsApprovedAccountError(null);
    const params = new URLSearchParams();

    params.append("userId", userId);

    axios
      .get(`${API_URL}account/admin/byUser`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setUserAccount(response?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveAccountRequest = (e) => {
    const body = {
      accountId: userAccount?._id,
    };
    axios
      .put(`${API_URL}admin/account/approve`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        fetchUserAccount();
      })
      .catch((err) => {
        setIsApprovedAccountError(err.response.data.message);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>user Details| Mena Checkout</title>{" "}
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="users" breadcrumbItem="user Details" />

          <Card>
            <CardBody>
              <CardTitle className="mb-4">
                {user?.firstName} {user?.lastName} Details
              </CardTitle>

              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">First Name :</th>
                      <td>{user?.firstName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Last Name :</th>
                      <td>{user?.lastName}</td>
                    </tr>
                    {user?.gender && (
                      <tr>
                        <th scope="row">Gender</th>
                        <td>{user?.gender}</td>
                      </tr>
                    )}
                    <tr>
                      <th scope="row">Phone :</th>
                      <td>{user?.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email :</th>
                      <td>{user?.email}</td>
                    </tr>
                    {user?.username && (
                      <tr>
                        <th scope="row">User Name :</th>
                        <td>{user?.username}</td>
                      </tr>
                    )}
                    <tr>
                      <th scope="row">Role :</th>
                      <td>{user?.role}</td>
                    </tr>

                    <tr>
                      <th scope="row">Created AT :</th>
                      <td>{moment(user?.createdAt).format("DD-MM-YYYY")}</td>
                    </tr>

                    <tr>
                      <th scope="row">isActive:</th>
                      <td>{user?.isActive === true ? "True" : "False"}</td>
                    </tr>

                    <tr>
                      <th scope="row">isApproved:</th>
                      <td className="font-size-14 mt-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={user?.isApproved}
                          id="flexCheckDefault"
                          defaultChecked={user?.isApproved}
                          onClick={approveRequest}
                          disabled={user?.isApproved ? true : false}
                        />
                        {isApprovedError && (
                          <span className="ms-3 text-danger">
                            {isApprovedError}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">isBlocked:</th>
                      <td className="font-size-14 mt-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={user?.isBlocked}
                          id="flexCheckDefault"
                          defaultChecked={user?.isBlocked}
                          onClick={blockRequest}
                        />
                        {isBlockedError && (
                          <span className=" ms-3 text-danger">
                            {isBlockedError}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">isValidated:</th>
                      <td className="font-size-14 mt-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={user?.isValidated}
                          id="flexCheckDefault"
                          defaultChecked={user?.isValidated}
                          onClick={validateRequest}
                          disabled={user?.isValidated ? true : false}
                        />
                        {isValidatedError && (
                          <span className="ms-3 text-danger">
                            {isBlockedError}
                          </span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Account Details</CardTitle>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Domain Name :</th>
                      <td>{userAccount?.domainName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Balance :</th>
                      <td>{userAccount?.balance}</td>
                    </tr>
                    <tr>
                      <th scope="row">Currency :</th>
                      <td>{userAccount?.currency}</td>
                    </tr>

                    <tr>
                      <th scope="row">isApproved:</th>
                      <td className="font-size-14 mt-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={userAccount?.isApproved}
                          id="flexCheckDefault"
                          defaultChecked={userAccount?.isApproved}
                          onClick={approveAccountRequest}
                          disabled={userAccount?.isApproved ? true : false}
                        />
                        {isApprovedAccountError && (
                          <span className="ms-3 text-danger">
                            {isApprovedAccountError}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">isDefault:</th>
                      <td>{userAccount?.isDefault ? "True" : "False"}</td>
                    </tr>
                    <tr>
                      <th scope="row">isDeleted:</th>
                      <td>{userAccount?.isDeleted ? "True" : "False"}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
UserDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(UserDetails);

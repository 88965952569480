import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";

import CustomerTransactions from "./CustomerTransactions";

const CustomerDetails = () => {
  const [customer, setCustomer] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    fetchSingleCustomerRequest();
  }, []);

  const fetchSingleCustomerRequest = () => {
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}customer/admin/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setCustomer(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customer Details| Mena Checkout</title>{" "}
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Customers" breadcrumbItem="Customer Details" />

          <Card>
            <CardBody>
              <CardTitle className="mb-4">{customer?.name} Details</CardTitle>

              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Name :</th>
                      <td>{customer?.customer?.name}</td>
                    </tr>

                    <tr>
                      <th scope="row">Phone :</th>
                      <td>{customer?.customer?.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email :</th>
                      <td>{customer?.customer?.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">Domain Name :</th>
                      <td>{customer?.customer?.account?.domainName}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
            {customer?.paymentMethod && (
              <CardBody>
                <CardTitle className="mb-4">Payment Method Details</CardTitle>

                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Card :</th>
                        <td>{customer?.paymentMethod?.card?.brand}</td>
                      </tr>
                      <tr>
                        <th scope="row">Country :</th>
                        <td>{customer?.paymentMethod?.card?.country}</td>
                      </tr>

                      <tr>
                        <th scope="row">Expired :</th>
                        <td>
                          {customer?.paymentMethod?.card?.exp_month}-
                          {customer?.paymentMethod?.card?.exp_year}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Last 4 Numbers :</th>
                        <td>{customer?.paymentMethod?.card?.last4}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            )}
          </Card>

          <CustomerTransactions customerId={id} />
        </Container>
      </div>
    </React.Fragment>
  );
};
CustomerDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(CustomerDetails);

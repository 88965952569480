import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
// import UserBankAccounts from "./UserBankAccounts";

const SubscriptionDetails = () => {
  const [subscription, setSubscription] = useState();

  const [isCancelled, setIsCanceledError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSingleSubscriptionRequest();
  }, []);

  const fetchSingleSubscriptionRequest = () => {
    setIsCanceledError(null);
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}subscription/admin/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setSubscription(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const cancelRequest = (e) => {
    const body = {
      id,
    };
    axios
      .post(`${API_URL}subscription/admin/cancel`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        fetchSingleSubscriptionRequest();
      })
      .catch((err) => {
        setIsCanceledError(err.response.data.message);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Subscription Details| Mena Checkout</title>{" "}
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            title="Subscription"
            breadcrumbItem="Subscription Details"
          />

          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Price :</th>
                      <td>
                        {" "}
                        {subscription?.items?.data[0]?.price?.unit_amount /
                          100}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Currency :</th>
                      <td>{subscription?.currency.toUpperCase()}</td>
                    </tr>
                    <tr>
                      <th scope="row">Created:</th>
                      <td>
                        {" "}
                        {moment(subscription?.created * 1000).format(
                          "DD-MM-YYYY"
                        )}
                        {" , "}
                        {moment(subscription?.created).format("h:mm:ss a")}
                      </td>
                    </tr>
                    {subscription?.gender && (
                      <tr>
                        <th scope="row">Current Period Start :</th>
                        <td>
                          {" "}
                          {moment(
                            subscription?.current_period_start * 1000
                          ).format("DD-MM-YYYY")}
                          {" , "}
                          {moment(subscription?.current_period_start).format(
                            "h:mm:ss a"
                          )}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th scope="row">Current Period End :</th>
                      <td>
                        {moment(subscription?.current_period_end * 1000).format(
                          "DD-MM-YYYY"
                        )}
                        {" , "}
                        {moment(subscription?.current_period_end).format(
                          "h:mm:ss a"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Count :</th>
                      <td>
                        {" "}
                        {
                          subscription?.items?.data[0]?.price?.recurring
                            ?.interval_count
                        }{" "}
                        {
                          subscription?.items?.data[0]?.price?.recurring
                            ?.interval
                        }
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">Status:</th>
                      <td className="font-size-14 mt-1">
                        {subscription?.status}
                      </td>
                    </tr>
                  </tbody>
                  {subscription?.status !== "canceled" && (
                    <button
                      type="button"
                      className="btn create-btn btn-lg ms-2 mt-4"
                      onClick={cancelRequest}
                    >
                      Cancel Subscription
                      {isCancelled && (
                        <span className="text-danger ms-3">{isCancelled}</span>
                      )}
                    </button>
                  )}
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
SubscriptionDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(SubscriptionDetails);

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";

import { DebounceInput } from "react-debounce-input";
import { get } from "helpers/api_helper";
import Loader from "components/Common/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const RefundRequests = () => {
  const [refundRequests, setRefundRequests] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    fetchRefundRequests(0, limit);
    // fetchMerchants();
  }, []);

  const fetchRefundRequests = async (page, limit) => {
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("page", page);
    params.append("limit", limit);

    axios
      .get(`${API_URL}refund/admin/request/list`, {
        searchKey,
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setRefundRequests(response?.data.data._requests);
        setTotalRows(response?.data.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        history.push("./login");
      });
  };

  const handlePageChange = (page) => {
    fetchRefundRequests(page - 1, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchRefundRequests(page - 1, newPerPage);
  };

  const columns = [
    {
      name: "First Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.user?.firstName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.user?.lastName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.user?.phone}</h5>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.user?.email}</h5>
      ),
      sortable: true,
    },
    {
      name: "Account",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.account?.domainName}</h5>
      ),
      sortable: true,
    },
    {
      name: "isApproved",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row.isApproved ? "True" : "False"}
        </h5>
      ),
      sortable: true,
    },

    {
      name: " Details",
      cell: (row) => (
        <Link
          className="btn btn-secondary"
          to={`/refundrequest/details/${row._id}`}
        >
          Details
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditAdmin = (item) => {
    setModal(true);
    setModalValue("edit");
    setSelectedUser(item);
  };

  const onClickAdminDelete = (item) => {
    setSelectedUser(item);
    setDeleteModal(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Refund Requests | Mena Checkout</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Refund Requests" />

          {refundRequests?.length !== 0 ? (
            <>
              <Grid
                data={refundRequests}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Refund Requests</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
RefundRequests.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(RefundRequests);

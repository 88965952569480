import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import Loader from "components/Common/Loader";

const AdminCountryDetails = () => {
  const [country, setCountry] = useState();
  const [requestError, setRequestError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const params = useParams();
  const fileInputRef = useRef();

  const id = params.id;

  useEffect(() => {
    fetchSingleCountryRequest();
  }, []);

  const fetchSingleCountryRequest = async () => {
    setRequestError(null);
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}country/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setCountry(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const approveRequest = (e) => {
    const body = {
      id,
      isActive: e.target.checked,
    };

    axios
      .put(`${API_URL}country/active`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        fetchSingleCountryRequest();
      })
      .catch((err) => {
        setRequestError(err.response.data.message);
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Country Details| Mena Checkout</title>{" "}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Countries" breadcrumbItem="Country Details" />

          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Name :</th>
                      <td>{country?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Arabic Name :</th>
                      <td>{country?.nameAr}</td>
                    </tr>

                    <tr>
                      <th scope="row">Currency :</th>
                      <td>{country?.currency}</td>
                    </tr>
                    <tr>
                      <th scope="row">IsoCode :</th>
                      <td>{country?.isoCode}</td>
                    </tr>
                    <tr>
                      <th scope="row">isActive:</th>
                      <td className="font-size-14 mt-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={country?.isActive}
                          id="flexCheckDefault"
                          defaultChecked={country?.isActive}
                          onClick={approveRequest}
                        />
                        {requestError && (
                          <span className="text-danger ms-3">
                            {requestError}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">isDeleted :</th>
                      <td>{country?.isDeleted ? "true" : "false"}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <h3 className="mt-4">Payment Gateway Details</h3>
          {country?.paymentGatewayId.map((payment) => (
            <>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Name :</th>
                          <td>{payment?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Arabic Name :</th>
                          <td>{payment?.nameAr}</td>
                        </tr>

                        <tr>
                          <th scope="row">Description :</th>
                          <td>{payment?.description}</td>
                        </tr>
                        <tr>
                          <th scope="row">Arabic Description :</th>
                          <td>{payment?.descriptionAr}</td>
                        </tr>
                        <tr>
                          <th scope="row">Gateway Fees :</th>
                          <td>{payment?.gatewayFees}</td>
                        </tr>
                        <tr>
                          <th scope="row">Our Fees :</th>
                          <td>{payment?.ourFees}</td>
                        </tr>
                        <tr>
                          <th scope="row">isActive :</th>
                          <td>{payment?.isActive ? "true" : "false"}</td>
                        </tr>
                        <tr>
                          <th scope="row">isDefault :</th>
                          <td>{payment?.isDefault ? "true" : "false"}</td>
                        </tr>
                        <tr>
                          <th scope="row">isDeleted :</th>
                          <td>{payment?.isDeleted ? "true" : "false"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </>
          ))}
        </Container>
      </div>
    </React.Fragment>
  );
};
AdminCountryDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AdminCountryDetails);

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";

import { DebounceInput } from "react-debounce-input";
import { get } from "helpers/api_helper";

const API_URL = process.env.REACT_APP_API_URL;

const UserTransactions = ({ userId }: any) => {
  const [userTransactions, setUserTransactions] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // const [merchantOptions, setMerchantOptions] = useState([]);

  //   useEffect(() => {
  //     fetchUserTransactions(0, limit);
  //     // fetchMerchants();
  //   }, []);

  const fetchUserTransactions = async (page, limit) => {
    const params = new URLSearchParams();

    params.append("id", userId);

    axios
      .get(`${API_URL}transaction/admin/list/user`, {
        searchKey,
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setUserTransactions(response?.data.data);
        setTotalRows(response?.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchUserTransactions(page - 1, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchUserTransactions(page - 1, newPerPage);
  };

  const columns = [
    {
      name: "First Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.firstName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.lastName}</h5>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.email}</h5>
      ),
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.gender}</h5>
      ),
      sortable: true,
    },

    {
      name: "User Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.username}</h5>
      ),
      sortable: true,
    },
    {
      name: " Details",
      cell: (row) => (
        <Link className="btn btn-secondary" to={`/user/details/${row._id}`}>
          Details
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditAdmin = (item) => {
    setModal(true);
    setModalValue("edit");
    setSelectedUser(item);
  };

  const onClickAdminDelete = (item) => {
    setSelectedUser(item);
    setDeleteModal(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h3>Transactions</h3>
          {userTransactions?.length !== 0 ? (
            <>
              <Grid
                data={userTransactions}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>User Transactions</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
UserTransactions.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(UserTransactions);

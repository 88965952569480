import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import axios from "axios";
import React, { useState, useEffect } from "react";


import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import {useHistory} from 'react-router-dom';

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";


const API_URL = process.env.REACT_APP_API_URL;
 


//  <<<Exported function>>>>
const changePassword = () => {

  const [isSubmmiting, setIsSubmitting] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const history = useHistory();


  useEffect(() => {
  }, [successMsg]);

  const handleGoBackButton = () =>{
    history.goBack()
  }
  

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Old Password"),
      newPassword: Yup.string().required("Please Enter Your New Password"),
      confirmPassword: Yup
  .string()
  .required("Password confirming is required")
  .oneOf([Yup.ref('newPassword')], 'Passwords are not matching')
    }),

    onSubmit: async (values) => {
      const body = {
        password: values.password,
        newPassword: values.newPassword,
      };
      axios
      .post(`${API_URL}user/changePassword`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then(() => {
        setIsSubmitting(true)
        setErrorMsg("")
        validation.resetForm();
        setSuccessMsg("Password changed Successfully You will be redirect to your dashboard shortly");
        setTimeout(() => {
          // history.push('/countries');
          history.goBack()
        }, 2500);
        // setShowModal(false);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.message);
        console.log(err.response.data.message);
      });

    },
    
  });



  return (
    <React.Fragment>
      <MetaTags>
        <title>Change Password | Mena Checkout - Admin</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="main-bg ">
                  <Row>
                    <Col xs={7}>
                      <div className="text-light p-4">
                        {/* <h5 className="text-light">Welcome Back </h5> */}
                        <p>Changing Your Password</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle main-bg">
                          <img
                            src={logo}
                            width={50}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                  

                      <div className="mb-3">
                        <Label for="password" className="form-label">
                          Old Password
                        </Label>
                        <Input
                          name="password"
                          id="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Old Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div style={{ textAlign: `center`, color: `red` }}>
                      {errorMsg}
                      </div>

                      <div className="mb-3">
                        <Label for="newPassword" className="form-label">
                          New Password
                        </Label>
                        <Input
                          name="newPassword"
                          id="newPassword"
                          value={validation.values.newPassword || ""}
                          type="password"
                          placeholder="Enter New Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.newPassword &&
                            validation.errors.newPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.newPassword &&
                        validation.errors.newPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.newPassword}
                          </FormFeedback>
                        ) : null}
                      </div>



                      <div className="mb-3">
                        <Label for="confirmPassword" className="form-label">
                          Confirm Password
                        </Label>
                        <Input
                          name="confirmPassword"
                          id="confirmPassword"
                          value={validation.values.confirmPassword || ""}
                          type="password"
                          placeholder="Re-Enter New Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          // validate={value =>
                          //   validateConfirmPassword(values.newPassword, value)
                          // }
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />

                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                       







                      <div style={{ textAlign: `center`, color: `green`, fontWeight:`900`, fontSize:`22px` }}>
                      {successMsg}
                     
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn create-btn btn-block"
                          type="submit"
                          
                          disabled={isSubmmiting}
                        >
                          Submit
                        </button>
                      </div>
                   
                
                    </Form>

                    <div className="mt-3 d-grid">
                      <button
                          className="btn create-btn btn-block"
                          type="button"
                          onClick={handleGoBackButton}
                        >
                          Cancel
                        </button>
                      </div>
                  


                  </div>
               
                  

                 
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} by Mena Checkout. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Mena Checkout
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(changePassword);

changePassword.propTypes = {
  history: PropTypes.object,
};

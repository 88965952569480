import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;

const AdminsNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  selectedAdmin,
  fetchAdmins,
  merchantOptions,
}) => {
  const [requestError, setRequestError] = useState(null);
  const [handleErrorPlace, setHandleErrorPlace] = useState(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: (selectedAdmin && selectedAdmin.firstName) || "",
      lastName: (selectedAdmin && selectedAdmin.lastName) || "",
      phone: (selectedAdmin && selectedAdmin.phone) || "",
      username: (selectedAdmin && selectedAdmin.username) || "",
      email: (selectedAdmin && selectedAdmin.email) || "",
      password: (selectedAdmin && selectedAdmin.password) || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Please enter first name")
        .matches(
          /^[\u0600-\u06FFa-zA-Z\s]*[\u0600-\u06FFa-zA-Z][\u0600-\u06FFa-zA-Z\s]*$/,
          "Please Enter Valid Name"
        ),
      lastName: Yup.string()
        .required("Please Enter last name")
        .matches(
          /^[\u0600-\u06FFa-zA-Z\s]*[\u0600-\u06FFa-zA-Z][\u0600-\u06FFa-zA-Z\s]*$/,
          "Please Enter Valid Name"
        ),
      phone: Yup.string()
        .required("Phone is Required")
        .matches(phoneRegExp, "Phone number is not valid"),
      email: Yup.string().email().required("Email is required"),

      password: Yup.string()
        .matches()
        .required("Please enter Password")
        .matches(/^\s*[^ ]\S*$/, "Please Enter Valid Password"),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          id: selectedAdmin._id,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          email: values.email,
          username: values.username || null,
        };

        axios
          .put(`${API_URL}admin/update`, body, {
            headers: {
              userDevice: localStorage.getItem("userDevice"),
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json; charset=utf-8",
            },
          })
          .then(() => {
            fetchAdmins(0, 10);
            validation.resetForm();
            setShowModal(false);
          })
          .catch((err) => {
            setRequestError(err.response.data.message);
            setHandleErrorPlace("edit");
          });
      } else {
        const body = {
          phone: values["phone"],
          username: values["username"] || null,
          firstName: values["firstName"],
          lastName: values["lastName"],
          password: values["password"],
          email: values["email"],
        };

        axios
          .post(`${API_URL}admin/create`, body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json; charset=utf-8",
              userDevice: localStorage.getItem("userDevice"),
            },
          })
          .then(() => {
            fetchAdmins(0, 10);
            validation.resetForm();
            setShowModal(false);
          })
          .catch((err) => {
            setRequestError(err.response.data.message);
            setHandleErrorPlace("create");
          });
      }
      setRequestError(null);
      setHandleErrorPlace(null);
    },
  });

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onCloseClick();
        setRequestError();
        validation.resetForm();
      }}
      centered={true}
    >
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit Admin" : "Add Admin"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">First Name</Label>
                <Input
                  name="firstName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstName || ""}
                  invalid={
                    validation.touched.firstName && validation.errors.firstName
                      ? true
                      : false
                  }
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstName}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Last Name</Label>
                <Input
                  name="lastName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastName || ""}
                  invalid={
                    validation.touched.lastName && validation.errors.lastName
                      ? true
                      : false
                  }
                />
                {validation.touched.lastName && validation.errors.lastName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastName}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Phone</Label>
                <Input
                  name="phone"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>

              {modalValue === "new" ? (
                <div className="mb-3">
                  <Label className="form-label">Password</Label>
                  <Input
                    name="password"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
              ) : (
                ``
              )}
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Usernam (Optional)</Label>
                <Input
                  name="username"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          {modalValue === "edit" && handleErrorPlace === "edit" && (
            <p className="text-danger mt-2 text-center">{requestError}</p>
          )}
          {modalValue !== "edit" && handleErrorPlace === "create" && (
            <p className="text-danger mt-2 text-center">{requestError}</p>
          )}

          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    onCloseClick();
                    setRequestError();

                    validation.resetForm();
                  }}
                  className="btn create-border-btn save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn create-btn save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

AdminsNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  selectedAdmin: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchAdmins: PropTypes.func,
  merchantOptions: PropTypes.any,
};

export default AdminsNewAndEditModal;

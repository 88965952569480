import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import Loader from "components/Common/Loader";

const PackageRequestDetails = () => {
  const [packageRequest, setPackageRequest] = useState();
  const [loader, setLoader] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSinglepackageRequest();
  }, []);

  const fetchSinglepackageRequest = async () => {
    setLoader(true);
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}package/request/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setPackageRequest(response.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      {loader ? (
        <Loader />
      ) : (
        <div className="page-content">
          <MetaTags>
            <title>Package Request Details| Mena Checkout</title>{" "}
          </MetaTags>
          <Container fluid>
            <Breadcrumb
              title="Packages Requests"
              breadcrumbItem="Package Request Details"
            />
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  {packageRequest?.packageId.name} Details
                </CardTitle>

                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Package Name :</th>
                        <td>{packageRequest?.packageId.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Package Arabic Name :</th>
                        <td>{packageRequest?.packageId.nameAr}</td>
                      </tr>
                      <tr>
                        <th scope="row">Description :</th>
                        <td>{packageRequest?.packageId.description}</td>
                      </tr>
                      <tr>
                        <th scope="row">Arabic Description :</th>
                        <td>{packageRequest?.packageId.descriptionAr}</td>
                      </tr>
                      <tr>
                        <th scope="row">Price :</th>
                        <td>{packageRequest?.packageId.price}</td>
                      </tr>
                      <tr>
                        <th scope="row">isActive :</th>
                        <td>
                          {packageRequest?.packageId.isActive
                            ? "True"
                            : "False"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">isDeleed :</th>
                        <td>
                          {packageRequest?.packageId.isDeleted
                            ? "True"
                            : "False"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Account Details</CardTitle>
                <p className="text-muted mb-4">{packageRequest?.description}</p>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Account Balance :</th>
                        <td>{packageRequest?.account.balance}</td>
                      </tr>
                      <tr>
                        <th scope="row">Domain Name:</th>
                        <td>{packageRequest?.account.domainName}</td>
                      </tr>
                      <tr>
                        <th scope="row">isApproved :</th>
                        <td>
                          {packageRequest?.account.isActive ? "True" : "False"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">isDefault :</th>
                        <td>
                          {packageRequest?.account.isDefault ? "True" : "False"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">isDeleed :</th>
                        <td>
                          {packageRequest?.account.isDeleted ? "True" : "False"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">User Details</CardTitle>
                <p className="text-muted mb-4">
                  {packageRequest?.userId.description}
                </p>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">First Name :</th>
                        <td>{packageRequest?.userId.firstName}</td>
                      </tr>
                      <tr>
                        <th scope="row">Last Name :</th>
                        <td>{packageRequest?.userId.lastName}</td>
                      </tr>
                      <tr>
                        <th scope="row">Phone :</th>
                        <td>{packageRequest?.userId.phone}</td>
                      </tr>
                      <tr>
                        <th scope="row">Email :</th>
                        <td>{packageRequest?.userId.email}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};
PackageRequestDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PackageRequestDetails);

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";

import { DebounceInput } from "react-debounce-input";
import { get } from "helpers/api_helper";
import Loader from "components/Common/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import SubscriptionList from "components/Common/subscriptionList";

const Subscription = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Subscriptions | Mena Checkout</title>
      </MetaTags>
      <Breadcrumbs title="" breadcrumbItem="Subscriptions" />
      <div>
        <SubscriptionList status="active" />
      </div>
      <div>
        <SubscriptionList status="canceled" />
      </div>
      <div>
        <SubscriptionList status="unpaid" />
      </div>
      <div>
        <SubscriptionList status="incomplete" />
      </div>
    </React.Fragment>
  );
};
Subscription.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Subscription);

import PropTypes, { number } from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";

import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";

import { DebounceInput } from "react-debounce-input";
import { get } from "helpers/api_helper";
import Loader from "components/Common/Loader";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const TransactionsPagination = ({ detailId }: any) => {
  const [transactions, setTransactions] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [hasMorePages, setHasMorePages] = useState(false);
  const [nextPaginationPage, setNextPaginationPage] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [firstPage, setFirstPage] = useState(true);
  const history = useHistory();
  const customStyles = {
    headCells: {
      style: {
        background: "rgb(138 138 138)",
        color: "white",

        fontWeight: "bold",
        borderBottom: "1px solid #ddd",
        padding: "12px",
      },
    },
    table: {
      style: {
        border: "1px solid #ddd",
        borderRadius: "4px",
      },
    },
    rows: {
      style: {
        background: "#fff",
        color: "#333",
        "&:nth-child(odd)": {
          background: "#f5f5f5",
        },
        "&:hover": {
          background: "rgb(156 169 175 / 49%)",
        },
      },
    },
    cells: {
      style: {
        paddingRight: "30px",
      },
    },
    pagination: {
      style: {
        borderTop: "none",
        paddingTop: "60px",
      },
    },
  };
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    fetchTransaction(limit);
  }, []);

  const fetchTransaction = async (limit) => {
    const params = new URLSearchParams();
    params.append("limit", limit);
    detailId && params.append("customerId", detailId);
    setFirstPage(true);

    axios
      .get(`${API_URL}transaction/admin/search`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setTransactions(response?.data.data.data);
        setHasMorePages(response?.data.data.has_more);
        setNextPaginationPage(response?.data.data.next_page);
        setTotalRows(response?.data.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        history.push("./login");
      });
  };

  const fetchTransactionPagination = async (page, limit) => {
    setHasMorePages(null);
    const params = new URLSearchParams();
    params.append("page", nextPaginationPage);
    params.append("limit", limit);
    detailId && params.append("customerId", detailId);
    axios
      .get(`${API_URL}transaction/admin/search`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setTransactions(response?.data.data.data);
        setHasMorePages(response?.data.data.has_more);
        setNextPaginationPage(response?.data.data?.next_page);
        setTotalRows(response?.data.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        history.push("./login");
      });
  };

  const handleNextPageChange = async () => {
    fetchTransactionPagination(nextPaginationPage, limit);

    setFirstPage(false);
  };

  const columns = [
    {
      name: "Billing Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.billing_details.name}</h5>
      ),
      sortable: true,
    },

    {
      name: "Total Amount",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.amount / 100} {row?.currency.toUpperCase()}
        </h5>
      ),
      sortable: true,
    },

    {
      name: "Fees",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.balanceTransaction?.fee / 100}{" "}
          {row?.balanceTransaction?.currency.toUpperCase()}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Net",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.balanceTransaction?.net / 100}{" "}
          {row?.balanceTransaction?.currency.toUpperCase()}
        </h5>
      ),
      sortable: true,
    },

    {
      name: "Currency",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.currency.toUpperCase()}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Paid",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.paid ? "True" : "False"}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Refunded",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row?.refunded ? "True" : "False"}
        </h5>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row?.status}</h5>
      ),
      sortable: true,
    },
    {
      name: " Details",
      cell: (row) => (
        <Link
          className="btn btn-secondary"
          to={`/transaction/details/${row?.id}`}
        >
          Details
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditAdmin = (item) => {
    setModal(true);
    setModalValue("edit");
    setSelectedAdmin(item);
  };

  const onClickAdminDelete = (item) => {
    setSelectedAdmin(item);
    setDeleteModal(true);
  };

  return (
    <React.Fragment>
      <div className="page-content transaction-page">
        <MetaTags>
          <title>Transactions | Mena Checkout</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            title={detailId && `Transactions`}
            breadcrumbItem={!detailId && `Transactions`}
          />

          {transactions?.length !== 0 ? (
            <>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <DataTable
                        columns={columns}
                        data={transactions}
                        className="-mt-10"
                        customStyles={customStyles}
                        paginationServer
                      />
                      <div className="w-100 text-center custom-pagination">
                        <p className="m-0 me-3"> Rows Per Page: {limit}</p>
                        <button
                          disabled={firstPage}
                          className="pagination-prev-btn me-1"
                          onClick={() => fetchTransaction(limit)}
                        >
                          <MdOutlineKeyboardDoubleArrowLeft />
                        </button>
                        {/* <button onClick disabled>
                  Previous
                </button> */}
                        <button
                          className="pagination-next-btn"
                          onClick={handleNextPageChange}
                          disabled={!hasMorePages}
                        >
                          <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Transactions</CardTitle>
                <EmptyTable />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
TransactionsPagination.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(TransactionsPagination);

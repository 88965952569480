import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";
import CounteriesNewAndEditModal from "./CounteriesNewAndEditModal";

import { DebounceInput } from "react-debounce-input";
import Loader from "components/Common/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const Counteries = () => {
  const history = useHistory();
  const [counteries, setCounteries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [modal, setModal] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [merchantOptions, setMerchantOptions] = useState([]);

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    fetchCountries(0, limit);
  }, []);

  const fetchCountries = async (page, limit) => {
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("limit", limit);
    axios
      .get(`${API_URL}country/admin/list`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setCounteries(response?.data.data._countries);
        setTotalRows(response?.data.data.totalCount);
      })
      .catch((err) => {
        history.push("./login");
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    fetchCountries(page - 1, limit);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    fetchCountries(page - 1, newPerPage);
  };
  const onClickDeleteCountry = (item) => {
    setSelectedCountry(item);
    setDeleteModal(true);
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => <h5 className="font-size-12 text-dark">{row.name}</h5>,
      sortable: true,
    },
    {
      name: "Arabic Name",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.nameAr}</h5>
      ),
      sortable: true,
    },
    {
      name: "Iso Code",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.isoCode}</h5>
      ),
      sortable: true,
    },

    {
      name: "Currency",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">{row.currency}</h5>
      ),
      sortable: true,
    },
    {
      name: "isDeleted",
      selector: (row) => (
        <h5 className="font-size-12 text-dark">
          {row.isDeleted ? "True" : "False"}
        </h5>
      ),
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <ul className="list-inline font-size-20 contact-links mb-0 text-center cursor-pointer">
          <Link to="#">
            <i
              className="mdi mdi-delete font-size-18 "
              style={{
                color: "#1525ac",
              }}
              id="deletetooltip"
              onClick={() => onClickDeleteCountry(row)}
            ></i>
          </Link>
          <li className="list-inline-item px-2">
            <Link to="#">
              <i
                style={{
                  color: "#333333",
                }}
                className="mdi mdi-pencil font-size-18 "
                id="edittooltip"
                onClick={() => handleEditCountry(row)}
              ></i>
            </Link>
          </li>
        </ul>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: " Details",
      cell: (row) => (
        <Link className="btn btn-secondary" to={`/country/details/${row._id}`}>
          Details
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const addNew = () => {
    setModal(true);
    setModalValue("new");
  };

  const handleEditCountry = async (item) => {
    setModal(true);
    setModalValue("edit");
    setSelectedCountry(item);
  };
  const handleDeletePayment = (item) => {
    const body = {
      id: item?._id,
    };
    axios
      .delete(`${API_URL}country/delete`, {
        data: body,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then(() => {
        fetchCountries(0, limit);
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteModal(false);
  };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePayment}
        onCloseClick={() => setDeleteModal(false)}
        itemToBeDeleted={selectedCountry}
      />

      <CounteriesNewAndEditModal
        show={modal}
        setShowModal={setModal}
        modalValue={modalValue}
        onCloseClick={() => setModal(false)}
        selectedCountry={modalValue === "new" ? {} : selectedCountry}
        fetchCountries={fetchCountries}
      />

      <div className="page-content">
        <MetaTags>
          <title>Country| Mena Checkout</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Countries" />
          <Row>
            <Col>
              <div className="text-sm-end mb-2">
                <button className="btn font-16 create-btn" onClick={addNew}>
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Country
                </button>
              </div>
            </Col>
          </Row>

          {counteries?.length !== 0 ? (
            <>
              <Grid
                data={counteries}
                columns={columns}
                totalRows={totalRows}
                limit={limit}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <CardTitle>Country</CardTitle>
                <EmptyTable />
                {}
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
Counteries.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Counteries);

import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import Loader from "components/Common/Loader";

const AdminTransactionDetails = () => {
  const [transaction, setTransaction] = useState();
  const [loader, setLoader] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSingleTransactionRequest();
  }, []);

  const fetchSingleTransactionRequest = async () => {
    setLoader(true);

    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}transaction/admin/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setTransaction(response.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  return (
    <React.Fragment>
      {loader ? (
        <Loader />
      ) : (
        <div className="page-content transactions-page">
          <MetaTags>
            <title>Transaction Details| Mena Checkout</title>{" "}
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb
              title="Transactions"
              breadcrumbItem="Transaction Details"
            />
            <CardTitle className="mb-4">
              {transaction?.transaction?.billing_details?.name} Details
            </CardTitle>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Transaction In Local Currency (Account Country&#39;s Currency)
                </CardTitle>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Billing Name :</th>
                        <td>
                          {transaction?.transaction?.billing_details?.name}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Phone :</th>
                        <td>
                          {transaction?.transaction?.billing_details?.phone}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Email :</th>
                        <td>
                          {transaction?.transaction?.billing_details?.email}
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Currency :</th>
                        <td>{transaction?.transaction?.currency}</td>
                      </tr>
                      <tr>
                        <th scope="row">Total Amount :</th>
                        <td>{transaction?.transaction?.amount / 100}</td>
                      </tr>

                      <tr>
                        <th scope="row">City :</th>
                        <td>
                          {
                            transaction?.transaction?.billing_details?.address
                              ?.city
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Country :</th>
                        <td>
                          {
                            transaction?.transaction?.billing_details?.address
                              ?.country
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Line1 :</th>
                        <td>
                          {
                            transaction?.transaction?.billing_details?.address
                              ?.line1
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Postal Code :</th>
                        <td>
                          {
                            transaction?.transaction?.billing_details?.address
                              ?.postal_code
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">State :</th>
                        <td>
                          {
                            transaction?.transaction?.billing_details?.address
                              ?.state
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Status :</th>
                        <td>{transaction?.transaction?.status}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Transaction Info (User Payment)
                </CardTitle>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Amount :</th>
                        <td>{transaction?.balanceTransaction?.amount / 100}</td>
                      </tr>
                      <tr>
                        <th scope="row">Fee :</th>
                        <td>{transaction?.balanceTransaction?.fee / 100}</td>
                      </tr>
                      <tr>
                        <th scope="row">Net :</th>
                        <td>{transaction?.balanceTransaction?.net / 100}</td>
                      </tr>
                      <tr>
                        <th scope="row">Currency :</th>
                        <td>{transaction?.balanceTransaction?.currency}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle className="mb-4">Payment Method</CardTitle>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      {transaction?.transaction?.payment_method_details && (
                        <>
                          <tr>
                            <th scope="row"> Type :</th>
                            <td>
                              {
                                transaction?.transaction?.payment_method_details
                                  ?.card.brand
                              }
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"> Country :</th>
                            <td>
                              {
                                transaction?.transaction?.payment_method_details
                                  ?.card.country
                              }
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"> Last 4 Numbers :</th>
                            <td>
                              {
                                transaction?.transaction.payment_method_details
                                  ?.card.last4
                              }
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"> Line1 Check :</th>
                            <td>
                              {
                                transaction?.transaction.payment_method_details
                                  ?.card?.checks?.address_line1_check
                              }
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"> Address Postal Code Check :</th>
                            <td>
                              {
                                transaction?.transaction.payment_method_details
                                  ?.card?.checks?.address_postal_code_check
                              }
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"> CVV Check :</th>
                            <td>
                              {
                                transaction?.transaction.payment_method_details
                                  ?.card?.checks?.cvc_check
                              }
                            </td>
                          </tr>

                          <tr>
                            <th scope="row"> Expiration Date :</th>
                            <td>
                              {
                                transaction?.transaction.payment_method_details
                                  ?.card?.exp_month
                              }{" "}
                              -
                              {
                                transaction?.transaction.payment_method_details
                                  ?.card?.exp_year
                              }
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};
AdminTransactionDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AdminTransactionDetails);

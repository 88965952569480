import PropTypes, { number } from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import EmptyTable from "../../components/Common/EmptyTable";
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";

import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Grid from "components/Common/Grid";
import DeleteModal from "../../components/Common/DeleteModal";

import { DebounceInput } from "react-debounce-input";
import { get } from "helpers/api_helper";
import Loader from "components/Common/Loader";
import DataTable from "react-data-table-component";
import TransactionsPagination from "components/Common/TransactionsPagination";

const API_URL = process.env.REACT_APP_API_URL;

const Transactions = () => {
  return <TransactionsPagination />;
};
Transactions.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Transactions);

import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import Loader from "components/Common/Loader";

const AdminRefundDetails = () => {
  const [refund, setRefund] = useState();
  const [loader, setLoader] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSingleTransactionRequest();
  }, []);

  const fetchSingleTransactionRequest = async () => {
    setLoader(true);

    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}refund/admin/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setRefund(response.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      {loader ? (
        <Loader />
      ) : (
        <div className="page-content transactions-page">
          <MetaTags>
            <title>Refund Details| Mena Checkout</title>{" "}
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Refunds" breadcrumbItem="Refund Details" />
            <CardTitle className="mb-4">Refund Details</CardTitle>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Amount:</th>
                        <td>{refund?.refund.amount / 100}</td>
                      </tr>

                      <tr>
                        <th scope="row">Currency :</th>
                        <td>{refund?.refund.currency}</td>
                      </tr>
                      <tr>
                        <th scope="row">CreatedAt :</th>
                        <td>
                          {moment(refund?.refund.created * 1000).format(
                            "DD-MM-YYYY"
                          )}
                          {" , "}
                          {moment(refund?.refund.created).format("h:mm:ss a")}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Status :</th>
                        <td>{refund?.refund.status}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Account Details</CardTitle>

                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Email :</th>
                        <td>{refund?.account.email}</td>
                      </tr>
                      <tr>
                        <th scope="row">Country:</th>
                        <td>{refund?.account.country}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Charge Details</CardTitle>
                <div className="table-responsive">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      {
                        <>
                          <tr>
                            <th scope="row"> Amount :</th>
                            <td>{refund?.charge?.amount / 100}</td>
                          </tr>
                          <tr>
                            <th scope="row"> Amount Captured :</th>
                            <td>{refund?.charge.amount_captured}</td>
                          </tr>
                          <tr>
                            <th scope="row"> Amount Refunded :</th>
                            <td>{refund?.charge.amount_refunded}</td>
                          </tr>

                          {/* <tr>
                            <th scope="row"> DescriptionAr :</th>
                            <td>{transaction?.paymentGateway.gatewayFees}</td>
                          </tr>
                          <tr>
                            <th scope="row"> DescriptionAr :</th>
                            <td>{transaction?.paymentGateway.ourFees}</td>
                          </tr>
                          <tr>
                            <th scope="row"> isActive :</th>
                            <td>
                              {transaction?.paymentGateway.isActive
                                ? "True"
                                : "False"}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"> isDefault :</th>
                            <td>
                              {transaction?.paymentGateway.isDefault
                                ? "True"
                                : "False"}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"> isDeleted :</th>
                            <td>
                              {transaction?.paymentGateway.isDeleted
                                ? "True"
                                : "False"}
                            </td>
                          </tr> */}
                        </>
                      }
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};
AdminRefundDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AdminRefundDetails);

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { withTranslation } from "react-i18next";

import TransactionsPagination from "components/Common/TransactionsPagination";

const CustomerTransactions = ({ customerId }: any) => {
  return <TransactionsPagination detailId={customerId} />;
};
CustomerTransactions.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(CustomerTransactions);

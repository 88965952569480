import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import Loader from "components/Common/Loader";

const RefundRequestDetails = () => {
  const [refundRequestItem, setRefundRequestItem] = useState();
  const [isApprovedError, setIsApprovedError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSingleRefundRequestItemRequest();
  }, []);

  const fetchSingleRefundRequestItemRequest = async () => {
    setIsApprovedError(null);
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}refund/admin/request/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setRefundRequestItem(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function handleApproveRefundRequest() {
    let body = {
      id,
    };
    axios
      .put(`${API_URL}refund/admin/request/approve`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then(() => {
        fetchSingleRefundRequestItemRequest();
      })
      .catch((err) => {
        setIsApprovedError(err.response.data.message);
      });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Refund Request Details| Mena Checkout</title>{" "}
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            title="Refund Requests"
            breadcrumbItem="Refund Request Details"
          />
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Refund User Details</CardTitle>

              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">User Name :</th>
                      <td>
                        {" "}
                        {refundRequestItem?._request.user.firstName}
                        {refundRequestItem?._request.user.lastName}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Email :</th>
                      <td> {refundRequestItem?._request.user.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">Phone :</th>
                      <td> {refundRequestItem?._request.user.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Created At :</th>
                      <td>
                        {" "}
                        {moment(refundRequestItem?._request.createdAt).format(
                          "DD-MM-YYYY"
                        )}
                        {" , "}
                        {moment(refundRequestItem?._request.createdAt).format(
                          "h:mm:ss a"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">isApproved :</th>
                      <td>
                        {refundRequestItem?._request.isApproved
                          ? "True"
                          : "False"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Domain Name :</th>
                      <td>{refundRequestItem?._request.account.domainName}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Refund Details </CardTitle>

              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Amount :</th>
                      <td> {refundRequestItem?.charge.amount}</td>
                    </tr>

                    <tr>
                      <th scope="row">Paid :</th>
                      <td>
                        {" "}
                        {refundRequestItem?.charge.paid ? "True" : "False"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Refunded :</th>
                      <td>
                        {" "}
                        {refundRequestItem?.charge.refunded ? "True" : "False"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          {!refundRequestItem?._request.isApproved ? (
            <button
              className="btn btn-secondary mb-3"
              onClick={handleApproveRefundRequest}
            >
              Approve Request
            </button>
          ) : (
            ``
          )}
          {isApprovedError && <p className="text-danger ">{isApprovedError}</p>}
        </Container>
      </div>
    </React.Fragment>
  );
};
RefundRequestDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(RefundRequestDetails);

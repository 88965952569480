import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import countryList from "react-select-country-list";

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("authUser");

const CounteriesNewAndEditModal = ({
  show,
  setShowModal,
  modalValue,
  onCloseClick,
  selectedCountry,
  fetchCountries,
  merchantOptions,
}) => {
  useEffect(() => {
    fetchPaymentGateWays();
  }, []);
  const [country, setCountry] = useState();
  const options = useMemo(() => countryList().getData(), []);
  const [checked, setChecked] = useState();
  const [paymentGetWays, setPaymentGateways] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState();
  const [payments, setPayments] = useState([]);
  const [paymentError, setPaymentError] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [requestCreateError, setRequestCreateError] = useState(null);
  const [requestEditError, setRequestEditError] = useState(null);
  const [handleErrorPlace, setHandleErrorPlace] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameAr: (selectedCountry && selectedCountry.nameAr) || "",
      currency: (selectedCountry && selectedCountry.currency) || "",
    },
    validationSchema: Yup.object({
      nameAr: Yup.string()
        .matches(
          /^[\u0600-\u06FF\s]*[\u0600-\u06FF][\u0600-\u06FF\s]*$/,
          "Must Be Arabic Letters"
        )
        .required("Please Enter  Arabic Name"),
      currency: Yup.string()
        .matches(
          /^[A-Za-z\s]*[A-Za-z][A-Za-z\s]*$/,
          "Please Enter English Letters"
        )
        .required("currency is required"),
    }),
    onSubmit: (values) => {
      if (modalValue === "edit") {
        const body = {
          name: country?.label || selectedCountry?.name,
          nameAr: values.nameAr,
          currency: values.currency,
          paymentGatewayId: payments[0]?.value,
          id: selectedCountry?._id,
          isActive:
            checked !== undefined
              ? checked === false
                ? "false"
                : "true"
              : `${selectedCountry?.isActive}`,
          isoCode: country?.value || selectedCountry?.isoCode,
        };

        axios
          .put(`${API_URL}country/update`, body, {
            headers: {
              userDevice: localStorage.getItem("userDevice"),
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json; charset=utf-8",
            },
          })
          .then(() => {
            fetchCountries(0, 10);
            setSelectedPayment();
            setCountry();
            setPaymentError(false);
            setHandleErrorPlace(null);
            setRequestCreateError(null);
            setRequestEditError(null);
            setCountryError(false);
            validation.resetForm();

            setShowModal(false);
          })
          .catch((err) => {
            setRequestEditError(err.response.data.message);

            setPaymentError(false);
          });
      } else {
        if (!country) {
          return setCountryError(true);
        }
        const body = {
          name: country?.label,
          nameAr: values.nameAr,
          currency: values.currency,
          paymentGatewayId: payments[0]?.value,
          isoCode: country?.value,
        };

        axios
          .post(`${API_URL}country/create`, body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json; charset=utf-8",
              userDevice: localStorage.getItem("userDevice"),
            },
          })
          .then(() => {
            fetchCountries(0, 10);
            setSelectedPayment();
            setCountry();
            setPaymentError(false);

            setCountryError(false);
            validation.resetForm();
            setShowModal(false);
          })
          .catch((err) => {
            setRequestCreateError(err.response.data.message);
            setPaymentError(false);
          });
      }
      setHandleErrorPlace(null);
      setRequestCreateError(null);
      setRequestEditError(null);
      setCountryError(false);
      setChecked();
    },
  });
  const handleSelectedPayment = (selectedOption) => {
    setSelectedPayment(selectedOption);
    setPaymentError(false);
  };

  const fetchPaymentGateWays = async () => {
    axios
      .get(`${API_URL}paymentgateway/admin/list`, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setPaymentGateways(response?.data.data);
        const FilteredPayments = response?.data.data?.map((item) => ({
          value: item._id,
          label: item.name,
        }));
        setPayments(FilteredPayments);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const changeHandler = (value) => {
    setCountry(value);
    setCountryError(false);
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onCloseClick();
        setCountryError(false);
        setPaymentError(false);
        setHandleErrorPlace(null);
        setRequestCreateError(null);
        setRequestEditError(null);
        setSelectedPayment();
        setCountry();
        setChecked();
        validation.resetForm();
      }}
      centered={true}
    >
      <ModalHeader tag="h4">
        {modalValue === "edit" ? "Edit country" : "Add country"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Select
                  options={options}
                  onChange={changeHandler}
                  placeholder="Select Country"
                  value={
                    modalValue === "edit"
                      ? country || {
                          label: selectedCountry?.name,
                          value: country?.isoCode,
                        }
                      : country
                  }
                />
              </div>
              {countryError ? (
                <p className="text-danger mt-3">Please Choose Country</p>
              ) : (
                ""
              )}
              <div className="mb-3">
                <Label className="form-label">Arabic Name</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Currency</Label>
                <Input
                  name="currency"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.currency || ""}
                  invalid={
                    validation.touched.currency && validation.errors.currency
                      ? true
                      : false
                  }
                />
                {validation.touched.currency && validation.errors.currency ? (
                  <FormFeedback type="invalid">
                    {validation.errors.currency}
                  </FormFeedback>
                ) : null}
              </div>

              {modalValue === "edit" ? (
                <>
                  <Label className="form-label">Payment</Label>
                  <Select
                    options={payments}
                    placeholder="Select Payment"
                    value={payments[0]}
                  />

                  <div className="mb-3 mt-3">
                    <Label className="form-label me-3">isActive</Label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      defaultChecked={selectedCountry?.isActive}
                      onClick={(e) => setChecked(e.target.checked)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Select
                    options={payments}
                    placeholder="Select Payment"
                    value={payments[0]}
                  />
                </>
              )}
            </Col>
          </Row>
          {requestCreateError && modalValue === "new" ? (
            <p className="text-danger text-center mt-2">{requestCreateError}</p>
          ) : null}
          {requestEditError && modalValue === "edit" ? (
            <p className="text-danger text-center mt-2">{requestEditError}</p>
          ) : null}
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCountryError(false);
                    setPaymentError(false);
                    setHandleErrorPlace(null);
                    setRequestCreateError(null);
                    setRequestEditError(null);
                    onCloseClick();
                    setSelectedPayment();
                    setCountry();
                    setChecked();
                    validation.resetForm();
                  }}
                  className="btn create-border-btn save-user m-2"
                >
                  Cancel
                </button>
                <button type="submit" className="btn create-btn save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

CounteriesNewAndEditModal.propTypes = {
  modalValue: PropTypes.string,
  onCloseClick: PropTypes.func,
  selectedCountry: PropTypes.any,
  show: PropTypes.any,
  setShowModal: PropTypes.func,
  fetchCountries: PropTypes.func,
  merchantOptions: PropTypes.any,
};

export default CounteriesNewAndEditModal;

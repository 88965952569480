import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import features from "../../assets/images/crypto/features-img/img-1.png";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";

const AdminRequestDetails = () => {
  const [admin, setAdmin] = useState();
  const [requestError, setRequestError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchSingleAdminRequest();
  }, []);

  const fetchSingleAdminRequest = async () => {
    setRequestError(null);
    const params = new URLSearchParams();
    // params.append("userDevice", localStorage.getItem("userDevice"));
    params.append("id", id);

    axios
      .get(`${API_URL}admin/details`, {
        params,
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        setAdmin(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveRequest = (e) => {
    const body = {
      id,
      active: e.target.checked,
    };

    axios
      .put(`${API_URL}admin/active`, body, {
        headers: {
          userDevice: localStorage.getItem("userDevice"),
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        fetchSingleAdminRequest();
      })
      .catch((err) => {
        setRequestError(err.response.data.message);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin Details| Mena Checkout</title>{" "}
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Admins" breadcrumbItem="Admin Details" />
          <Card>
            <CardBody>
              <CardTitle className="mb-4">{admin?.firstName} Details</CardTitle>
              <p className="text-muted mb-4">{admin?.description}</p>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">First Name :</th>
                      <td>{admin?.firstName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Last Name :</th>
                      <td>{admin?.lastName}</td>
                    </tr>
                    {admin?.username ? (
                      <tr>
                        <th scope="row">User Name :</th>
                        <td>{admin?.username}</td>
                      </tr>
                    ) : (
                      ``
                    )}
                    <tr>
                      <th scope="row">Email :</th>
                      <td>{admin?.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">Role :</th>
                      <td>{admin?.role}</td>
                    </tr>
                    <tr>
                      <th scope="row">Phone:</th>
                      <td>{admin?.phone}</td>
                    </tr>

                    {
                      <tr>
                        <th scope="row">isActive:</th>
                        <td className="font-size-14 mt-1">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={admin?.isActive}
                            id="flexCheckDefault"
                            defaultChecked={admin?.isActive}
                            onClick={approveRequest}
                          />
                          {requestError && (
                            <span className="text-danger ms-3">
                              {requestError}
                            </span>
                          )}
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
AdminRequestDetails.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AdminRequestDetails);
